.swiper {
    width: 100%;
    height: auto;
    margin: 0;
    padding-bottom: 1.5rem;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background-color: #ddd;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    /* 
    height: 18rem;
    */
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

$swiper-slide-max-width: 72rem;
$layout-breakpoint-small: 480px;

.swiper-slide.work {
    width: 91.666666dvw;
    max-width: $swiper-slide-max-width;
}

@media(min-width: $layout-breakpoint-small) {
    .swiper-slide.work {
        width: 83.333333dvw;
    }
}

:root {
    --swiper-theme-color:#f74902;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
    width: auto;
    bottom: auto;
    top: auto;
}